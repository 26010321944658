/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-extraneous-dependencies */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { AlarmClock, parseTime } from '@treasury/alarm-clock';
import {
    ActivityTrackingDto,
    NotificationListModelDto,
    NotificationModelDto,
    UserActivityTypeDto,
} from '@treasury/api/channel';
import { NavigationItem as NavItem, NavigationService } from '@treasury/core/navigation';
import { RealtimeService } from '@treasury/core/real-time';
import CorpaySsoRequest from '@treasury/domain/channel/requests/wires/corpay-sso';
import { AccountService } from '@treasury/domain/channel/services';
import { UserActivityService } from '@treasury/domain/channel/services/user-activity';
import {
    CutoffTimesDto,
    ProcessingTimeDto,
} from '@treasury/domain/channel/types/processing-time.dto';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { LogoutManager } from '@treasury/domain/services/logout';
import { CapitalizeKeys } from '@treasury/utils';
import camelCase from 'camelcase';
import moment from 'moment';
import { isProduction } from '../../../src/utilities/getEnvironment';
import { NavigationRouteMap } from '../../app.constants';

interface NavigationItem {
    key: keyof NavigationRouteMap | 'accounts';
    path: string | null;
    subMenus: NavigationItem[];
    isSubMenuVisible: boolean;
}

type RealTimeNotification = CapitalizeKeys<NotificationListModelDto> &
    CapitalizeKeys<ActivityTrackingDto>;

interface NotificationVm extends NotificationModelDto {
    isExpanded?: boolean;
    isFocused?: boolean;
}

interface LoginDigest {
    lastSuccessfulLogin: string;
    lastFailedLogin: string;
    lastFailedLoginIPAddress: string;
}
type AugmentedRootScope = ng.IRootScopeService & {
    authentication: any;
    closeEverything: () => void;
};

type AugmentedScope = ng.IScope & {
    url: string;
    userName: string;
    authentication: AugmentedRootScope['authentication'];
    navigationList: NavigationItem[];
    subMenuVisible: boolean;
    notifications: NotificationVm[];
    notificationsTotalUnreadCount: number;
    cutOffTimes: ProcessingTimeDto[];
    isNotificationPanelVisible: boolean;
    isCutOffTimesPanelVisible: boolean;
    areNotificationsEnabled: boolean;
    numVisibleNotifications: number;
    hasReturnReasonEntitlement: boolean;
    lastSuccessfulLogin: string;
    companyId: string;
    lastFailedLogin: string;
    lastFailedLoginIPAddress: string;
    cutOffTimeZone: string;
    unreadMessageCount: number;
    archiveAllNotifications: () => void;
    archiveNotification: (notification: NotificationModelDto) => void;
    areMoreNotificationsAvailable: () => void;
    getTime: (notification: NotificationModelDto) => string;
    getVisibleNotifications: () => Array<NotificationModelDto>;
    goToNotificationsInbox: () => void;
    goToPath: (event: Event, path: string) => void;
    hideCutOffTimes: () => void;
    hideNotifications: () => void;
    isItemSelected: (navigationItem: NavigationItem) => boolean;
    isSubItemSelected: (navigationItem: NavigationItem) => boolean;
    isNotificationFocused: (notification: NotificationModelDto) => boolean;
    isSubMenuVisible: (navigationItem: NavigationItem) => boolean;
    notificationKeyPress: (
        event: KeyboardEvent,
        index: number,
        notification: NotificationModelDto
    ) => void;
    numberOfUnreadNotifications: () => void;
    showMoreNotifications: () => void;
    showSubMenu: (event: Event, navigationItem: NavigationItem) => void;
    signOut: () => void;
    toggleCutOffTimes: (event: Event) => void;
    toggleNotificationExpanded: (event: Event, targetNotification: NotificationModelDto) => void;
    toggleNotifications: (event: Event) => void;
    gotoMessageCenter: () => void;
    isProduction: () => boolean;
};

HeaderController.$inject = [
    '$q',
    '$scope',
    '$rootScope',
    '$window',
    '$timeout',
    'securityService',
    'navigationService',
    'pagePushService',
    'modalService',
    'notificationsService',
    'toaster',
    'companyAccountsService',
    'billPayService',
    'usersService',
    'navigationRouteMap',
    'menulessOptions',
    'tmNavService',
    'TmDi',
];

/**
 * @param navigationService Service to get navigation items defined by the BSL.
 */
// eslint-disable-next-line @treasury/filename-match-export
export default function HeaderController(
    $q: ng.IQService,
    $scope: AugmentedScope,
    $rootScope: AugmentedRootScope,
    $window: ng.IWindowService,
    $timeout: ng.ITimeoutService,
    securityService: any,
    navigationService: any,
    pagePushService: any,
    modalService: any,
    notificationsService: any,
    toaster: any,
    companyAccountsService: any,
    billPayService: any,
    usersService: any,
    navigationRouteMap: any,
    menulessOptions: any,
    tmNavService: NavigationService,
    TmDi: DiContainer
) {
    // assign route data out of band so reliant functions can be synchronous
    let routeData: NavItem | undefined;
    tmNavService.routeChange$.subscribe(item => {
        routeData = item;
    });

    $scope.authentication = $rootScope.authentication;
    $scope.navigationList = [];
    $scope.subMenuVisible = false;
    $scope.notifications = [];
    $scope.notificationsTotalUnreadCount = 0;
    $scope.cutOffTimes = [];
    $scope.isNotificationPanelVisible = false;
    $scope.isCutOffTimesPanelVisible = false;
    $scope.areNotificationsEnabled = false;
    $scope.numVisibleNotifications = 10;
    $scope.lastSuccessfulLogin = '';
    $scope.lastFailedLogin = '';
    $scope.lastFailedLoginIPAddress = '';
    $scope.cutOffTimeZone = '';
    $scope.unreadMessageCount = 0;

    $rootScope.closeEverything = closeEverything;
    $scope.archiveAllNotifications = archiveAllNotifications;
    $scope.archiveNotification = archiveNotification;
    $scope.areMoreNotificationsAvailable = areMoreNotificationsAvailable;
    $scope.getTime = getTime;
    $scope.getVisibleNotifications = getVisibleNotifications;
    $scope.goToNotificationsInbox = goToNotificationsInbox;
    $scope.goToPath = goToPath;
    $scope.hideCutOffTimes = hideCutOffTimes;
    $scope.hideNotifications = hideNotifications;
    $scope.isItemSelected = isItemSelected;
    $scope.isSubItemSelected = isSubItemSelected;
    $scope.isNotificationFocused = isNotificationFocused;
    $scope.isSubMenuVisible = isSubMenuVisible;
    $scope.notificationKeyPress = notificationKeyPress;
    $scope.numberOfUnreadNotifications = numberOfUnreadNotifications;
    $scope.showMoreNotifications = showMoreNotifications;
    $scope.showSubMenu = showSubMenu;
    $scope.signOut = signOut;
    $scope.toggleCutOffTimes = toggleCutOffTimes;
    $scope.toggleNotificationExpanded = toggleNotificationExpanded;
    $scope.toggleNotifications = toggleNotifications;
    $scope.gotoMessageCenter = gotoMessageCenter;
    $scope.isProduction = isProduction;

    $scope.$on('removeNotifications', (event, data) => {
        $scope.notificationsTotalUnreadCount -= data.length;
    });

    $scope.$on('addNotifications', (event, data) => {
        $scope.notificationsTotalUnreadCount += data.length;
    });

    async function listenForNotifications() {
        const realTimeService = await TmDi.getAsync(RealtimeService);

        await realTimeService.subscribe<RealTimeNotification>(
            'updateNotificationList',
            notificationResponse => {
                $timeout(() => {
                    $scope.notifications = notificationResponse.TopMessages || [];
                    $scope.notificationsTotalUnreadCount = notificationResponse.TotalUnreadCount;
                    showAlerts(notificationResponse.TopMessages || []);
                    const notificationIds = $scope.notifications.map(
                        notification => notification.id
                    );
                    const activityId = notificationResponse.ActivityId;
                    const parentActivityId = notificationResponse.ParentActivityId;
                    realTimeService.publish(
                        'acknowledgeNotifications',
                        activityId,
                        parentActivityId,
                        notificationIds
                    );
                });
            }
        );

        await realTimeService.subscribe<number>('updateMessageCenterCount', response => {
            $timeout(() => {
                const originalMessageCount = $scope.unreadMessageCount;

                if (originalMessageCount !== response) {
                    $rootScope.$broadcast('messageCountUpdated');
                }

                $scope.unreadMessageCount = response;
            });
        });
    }

    function showMoreNotifications() {
        if (areMoreNotificationsAvailable()) {
            $scope.numVisibleNotifications += 10;
        }
    }

    function areMoreNotificationsAvailable() {
        return $scope.numVisibleNotifications < $scope.notifications.length;
    }

    function getVisibleNotifications() {
        return $scope.notifications.slice(0, $scope.numVisibleNotifications);
    }

    function loadNavigationList(): Promise<void> {
        return navigationService.getNavigation().then((navItems: NavigationItem[]) => {
            navItems.forEach(navigationModel => {
                mapApplicationPath(navigationModel, navigationRouteMap);
            });
            $scope.navigationList = navItems;
        });
    }

    function mapApplicationPath(navigationModel: NavigationItem, pathMap: NavigationRouteMap) {
        if (navigationModel.key in pathMap) {
            navigationModel.path = pathMap[navigationModel.key as keyof NavigationRouteMap];
        } else if (navigationModel.key.indexOf('businessBillPay') >= 0) {
            navigationModel.path = `payables.billpay.${navigationModel.key}`;
        } else if (
            navigationModel.key.indexOf('electronicDocuments') >= 0 ||
            navigationModel.key.indexOf('wausauStatements') >= 0 ||
            navigationModel.key.indexOf('bdieStatements') >= 0
        ) {
            navigationModel.path = `ir.${navigationModel.key}`;
        }

        if (navigationModel.subMenus.length > 0) {
            navigationModel.subMenus.forEach(navigation => {
                mapApplicationPath(navigation, pathMap);
            });
        }
    }

    function gotoMessageCenter() {
        return tmNavService.navigate('message-center');
    }

    function signOut() {
        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Log Out',
            headerText: 'Confirm Log Out',
            bodyText: 'Are you sure you want to log out from the application?',
            submit() {
                $modalInstance.close();
                const logoutManager = TmDi.get(LogoutManager);
                const logoutPromise = logoutManager.logOut('UserInitiated');
                const uisFfPromise = TmDi.getAsync(FeatureFlagService).then(ffService =>
                    ffService.isEnabled(Feature.UisEnabled)
                );

                $q.all([uisFfPromise, logoutPromise]).then(([isUisEnabled]) => {
                    const url = isUisEnabled ? 'logout' : 'login';
                    tmNavService.navigate(url);
                });
            },
        };

        const $modalInstance = modalService.showModal({}, modalOptions);
    }

    function showSubMenu(event: Event, navigationItem: NavigationItem) {
        event.stopPropagation();

        const open = !navigationItem.isSubMenuVisible;
        closeEverything();
        navigationItem.isSubMenuVisible = open;
        $scope.subMenuVisible = open;
    }

    function isSubMenuVisible(navigationItem: NavigationItem) {
        return navigationItem.isSubMenuVisible;
    }

    function closeEverything() {
        $scope.navigationList.forEach(navigationItem => {
            navigationItem.isSubMenuVisible = false;
        });
        $scope.subMenuVisible = false;
        $scope.isNotificationPanelVisible = false;
        $scope.isCutOffTimesPanelVisible = false;
    }

    function goToPath(event: Event, path: string) {
        event.stopPropagation();
        closeEverything();

        switch (path) {
            case 'payables.billpay.businessBillPay_frame':
                return gotoBillPay(false);
            case 'payables.billpay.businessBillPay_tab':
                return gotoBillPay(true);
            case 'payables.wire.createCorpayWire':
                return CorpaySsoRequest.getCorpaySsoUrl()
                    .then(response => {
                        $window.open(response.content, '_blank');
                        UserActivityService.saveUserActivity(
                            UserActivityTypeDto.Wire,
                            'User Navigated to Create FX Wire'
                        );
                    })
                    .catch(() => {
                        modalService.showModal(
                            {},
                            {
                                alertType: 'Error',
                                isAlert: true,
                                summaryText:
                                    'An error has occurred when attempting to log you into Corpay. Please try again or contact Corpay to access their site directly.',
                            }
                        );
                    });
                break;
            case 'ir.electronicDocuments_frame':
                return gotoElectronicDocs('frame');
            case 'ir.electronicDocuments_tab':
                return gotoElectronicDocs('tab');
            case 'ir.electronicDocuments_self':
                return gotoElectronicDocs('self');
            case 'ir.dashboard':
                return tmNavService.navigate('ir.dashboard', { tab: 'all' }, { reload: true });
            case 'ir.dashboard.favorite':
                return tmNavService.navigate(
                    'ir.dashboard.favorite',
                    { tab: 'favorite' },
                    { reload: true }
                );
            case 'ir.dashboard.custom':
                return tmNavService.navigate(
                    'ir.dashboard.custom',
                    { tab: 'custom' },
                    { reload: true }
                );
            case 'ir.dashboard.standard':
                return tmNavService.navigate(
                    'ir.dashboard.standard',
                    { tab: 'standard' },
                    { reload: true }
                );
            case 'dark':
                return tmNavService.navigate('dark');
            default:
                return tmNavService.navigate(path, {}, { reload: true, inherit: false });
        }
    }

    async function gotoBillPay(newTab: boolean) {
        const response = await (billPayService.getBillPayUrl() as Promise<
            { url: string; sessionId: string } | undefined
        >);

        if (response) {
            const billPayForm = angular.element('form#billPayForm')[0] as HTMLFormElement;
            billPayForm.action = response.url;
            billPayForm.target = newTab ? '_blank' : 'billPayIframe';

            const billPayInput = angular.element('form#billPayForm #p1')[0] as HTMLInputElement;
            billPayInput.value = response.sessionId;

            if (newTab) {
                billPayForm.submit();
            } else {
                tmNavService.navigate('payables.billpay.businessBillPay');
            }
        } else {
            modalService.showModal(
                {},
                {
                    alertType: 'Error',
                    isAlert: true,
                    summaryText: 'Unable to open Bill Pay.',
                }
            );
        }
    }

    async function gotoElectronicDocs(mode: 'tab' | 'frame' | 'self') {
        switch (mode) {
            case 'tab':
                return usersService.getElectronicDocumentSso().then((response: { url: string }) => {
                    if (response) {
                        $scope.url = response.url;
                        $window.open($scope.url, '_blank');
                    } else {
                        modalService.showModal(
                            {},
                            {
                                alertType: 'Error',
                                isAlert: true,
                                summaryText: 'Unable to load Electronic Documents.',
                            }
                        );
                    }
                }) as Promise<void>;
            case 'frame':
                return tmNavService.navigate('ir.electronicDocuments');

            default:
                return tmNavService.navigate('ir.electronicDocuments', {}, { reload: true });
        }
    }

    function isItemSelected(navigationItem: NavigationItem) {
        if (!routeData) {
            return false;
        }

        // Exception for research transactions, which is nested inside of the IR parent state.
        if (routeData.route === 'ir.rt') {
            return navigationItem.key === 'accounts';
        }

        return navigationItem.key === routeData.route.split('.')[0];
    }

    function isSubItemSelected(navigationItem: NavigationItem) {
        if (!routeData) {
            return false;
        }

        return routeData.route === navigationItem.path;
    }

    function getTime(notification: NotificationVm) {
        const timestamp = moment(notification.createdOn);
        if (notification.isExpanded) {
            return timestamp.format('HH:mmA MMM D, YYYY');
        }
        return timestamp.fromNow();
    }

    function archiveAllNotifications() {
        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Archive',
            headerText: 'Confirm Archive',
            bodyText: 'Would you like to archive all of the notifications?',
            submit() {
                const ids: string[] = [];

                $scope.notifications.forEach(notification => {
                    ids.push(notification.id.toString());
                });
                notificationsService.archive(ids);
                $scope.notificationsTotalUnreadCount = 0;
                $scope.notifications = [];

                $modalInstance.close();
            },
        };

        const $modalInstance = modalService.showModal({}, modalOptions);
    }

    function archiveNotification(notification: NotificationModelDto) {
        notificationsService.archive([notification.id]);
        $scope.notifications.splice($scope.notifications.indexOf(notification), 1);
        $scope.notificationsTotalUnreadCount -= 1;
    }

    function toggleNotificationExpanded(event: Event, targetNotification: NotificationVm) {
        $scope.notifications.forEach(notification => {
            if (notification !== targetNotification) {
                notification.isExpanded = false;
            }
        });

        event.stopPropagation();
        targetNotification.isExpanded = !targetNotification.isExpanded;

        if (targetNotification.isRead === false) {
            $scope.notificationsTotalUnreadCount -= 1;
            targetNotification.isRead = true;
            notificationsService.setRead([targetNotification.id], true);
        }
    }

    function goToNotificationsInbox() {
        return tmNavService.navigate('notifications');
    }

    function toggleNotifications(event: Event) {
        const newValue = !$scope.isNotificationPanelVisible;
        closeEverything();
        $scope.isNotificationPanelVisible = newValue;
        event.stopPropagation();

        if ($scope.isNotificationPanelVisible) {
            navigationService.userActivityAudit(menulessOptions.Notifications);
        }
    }

    function hideNotifications() {
        $scope.isNotificationPanelVisible = false;
    }

    function toggleCutOffTimes(event: Event) {
        const newValue = !$scope.isCutOffTimesPanelVisible;
        closeEverything();
        $scope.isCutOffTimesPanelVisible = newValue;
        event.stopPropagation();
    }

    function hideCutOffTimes() {
        $scope.isCutOffTimesPanelVisible = false;
    }
    function numberOfUnreadNotifications() {
        return $scope.notificationsTotalUnreadCount;
    }

    function notificationKeyPress(event: KeyboardEvent, index: number) {
        switch (event.which) {
            // Up arrow key
            case 38:
                if (index - 1 >= 0) {
                    resetOtherNotifications();
                    $scope.notifications[index - 1].isFocused = true;
                    $scope.notifications[index - 1].isExpanded = true;
                    $scope.notifications[index - 1].isRead = true;
                }
                break;

            // Down arrow key
            case 40:
                if (index + 1 <= $scope.getVisibleNotifications().length - 1) {
                    resetOtherNotifications();
                    $scope.notifications[index + 1].isFocused = true;
                    $scope.notifications[index + 1].isExpanded = true;
                    $scope.notifications[index + 1].isRead = true;
                }
                break;
            default:
                break;
        }

        function resetOtherNotifications() {
            $scope.notifications.forEach(otherNotification => {
                otherNotification.isExpanded = false;
                otherNotification.isFocused = false;
            });
        }

        event.stopPropagation();
    }

    function isNotificationFocused(notification: NotificationVm) {
        return !!notification.isFocused;
    }

    function loadCutoffTimes() {
        const fiClock = AlarmClock.getInstance();
        companyAccountsService.getCutoffTimes().then((response: CutoffTimesDto) => {
            fiClock.time = response.currentFiTime;
            fiClock.timeZone = /(All (\w+))/.exec(response.timeZone)?.[2] || response.timeZone;

            response.processingCutoffs.forEach(cutoff => {
                const { startTime, cutoffTime, productType } = cutoff;
                if (startTime) {
                    const startKeyName = camelCase(`${productType} start`);
                    fiClock.setAlarm(startKeyName, startTime);
                }
                if (cutoffTime) {
                    const cutoffKeyName = camelCase(`${productType} cutoff`);
                    fiClock.setAlarm(cutoffKeyName, cutoffTime);
                    const cutoffTimeObject = parseTime(cutoffTime);
                    cutoffTimeObject.subtractMinutes(30);
                    fiClock.setAlarm(`${cutoffKeyName}-30Minutes`, cutoffTimeObject);
                }
            });

            response.processingCutoffs = response.processingCutoffs.map(cutoff => {
                cutoff.cutoffTime = moment(`${moment().format('l')} ${cutoff.cutoffTime}`).format(
                    'LT'
                );
                return cutoff;
            });
            $scope.cutOffTimes = response.processingCutoffs;
            $scope.cutOffTimeZone = response.timeZone;
        });
    }

    // Private
    function showAlerts(notifications: NotificationModelDto[]) {
        const maxAlerts = 4;
        const alertNotifications = notifications
            .filter(notification => notification.showAlert)
            .reverse();
        let numRemaining;
        let lastNotification;

        if (!alertNotifications) {
            return;
        }

        alertNotifications.slice(0, maxAlerts - 1).forEach(notification => {
            toaster.alert(notification.subject, notification.detail);
        });

        // If there are two or more additional notifications then show the quantity.
        if (alertNotifications.length > maxAlerts) {
            numRemaining = alertNotifications.length - (maxAlerts - 1);
            toaster.alert(
                `+${numRemaining} more notifications`,
                `There are ${numRemaining} more in your inbox.`
            );

            // If there is only one additional notification then show its contents.
        } else if (alertNotifications.length === maxAlerts) {
            lastNotification = alertNotifications[alertNotifications.length - 1];
            toaster.alert(lastNotification.subject, lastNotification.detail);
        }
    }

    async function init() {
        loadNavigationList();
        loadCutoffTimes();
        listenForNotifications();
        const accountService = await AccountService.getInstance();
        const settings = await accountService.getUserSettings();
        const { companyId } = await accountService.getCurrentUser();

        $scope.companyId = companyId;

        $scope.areNotificationsEnabled = settings.areNotificationsEnabled;
        $scope.unreadMessageCount = settings.unreadMessageCount;

        const recentLogins: LoginDigest = await securityService.recentLogins();
        $scope.lastSuccessfulLogin = recentLogins.lastSuccessfulLogin;
        if (recentLogins.lastFailedLogin !== null) {
            $scope.lastFailedLogin = recentLogins.lastFailedLogin;
            $scope.lastFailedLoginIPAddress = recentLogins.lastFailedLoginIPAddress;
        } else {
            $scope.lastFailedLogin = '';
        }

        const recentNotifications: NotificationListModelDto =
            await notificationsService.getRecent();
        const topMessages = recentNotifications.topMessages || [];
        $scope.notifications = topMessages;
        $scope.notificationsTotalUnreadCount = recentNotifications.totalUnreadCount;
        numberOfUnreadNotifications();
        showAlerts(topMessages);

        const user = pagePushService.getUser();
        $scope.userName = user.userName;

        if (document.title.includes('💼')) {
            document.title = `${document.title.slice(0, document.title.indexOf('💼') + 2)} ${
                user.institution
            } / ${user.companyId} - Treasury Management`;
        }
    }

    init();
}
